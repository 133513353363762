import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide from "./Slide";
import Modal from "../Modal";
import { ApiDataContext } from "../../Contexts/ApiData";

const MainCarousel = ({
  carousel,
  presidentImage,
  presidentMessage,
  presidentContent,
}) => {
  const slider = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const { isLoading } = useContext(ApiDataContext);

  const beforeChange = (prev, next) => {
    if (prev == 0) {
      const prevSlideElement = slider.current.innerSlider.list.querySelector(
        `[data-index="${prev}"]`
      );
      if (!prevSlideElement) return;
      const prevSlideImage = prevSlideElement.querySelector(".banner-col-img");
      const prevSlideHeading = prevSlideElement.querySelector("h2");
      const prevSlideSummary = prevSlideElement.querySelector(".slidecontent");
      const prevSlideButton = prevSlideElement.querySelector(".slide-btn");

      if (
        prevSlideElement &&
        prevSlideImage &&
        prevSlideHeading &&
        prevSlideSummary &&
        prevSlideButton
      ) {
        prevSlideHeading?.classList.remove("fadeInUp");
        prevSlideHeading?.classList.remove("animated");
        prevSlideHeading.style.opacity = 0;

        prevSlideSummary?.classList.remove("fadeInUp");
        prevSlideSummary?.classList.remove("animated");
        prevSlideSummary.style.opacity = 0;

        prevSlideButton?.classList.remove("fadeInUp");
        prevSlideButton?.classList.remove("animated");
        prevSlideButton.style.opacity = 0;

        prevSlideImage?.classList.remove("zoomInImage");
        prevSlideImage?.classList.remove("animated");
        prevSlideImage.style.opacity = 0;
      }
    }
  };

  const afterChange = (prev, next) => {
    if (prev == 0) {
      const currentSlideElement = slider.current.innerSlider.list.querySelector(
        `[data-index="${prev}"]`
      );
      if (!currentSlideElement) return;
      const currentSlideImage =
        currentSlideElement.querySelector(".banner-col-img");

      const prevSlideHeading = currentSlideElement.querySelector("h2");
      const prevSlideSummary =
        currentSlideElement.querySelector(".slidecontent");
      const prevSlideButton = currentSlideElement.querySelector(".slide-btn");

      if (
        currentSlideElement &&
        currentSlideImage &&
        prevSlideHeading &&
        prevSlideSummary &&
        prevSlideButton
      ) {
        prevSlideHeading?.classList.add("fadeInUp");
        prevSlideHeading?.classList.add("animated");
        prevSlideHeading.style.opacity = 1;

        prevSlideSummary?.classList.add("fadeInUp");
        prevSlideSummary?.classList.add("animated");
        prevSlideSummary.style.opacity = 1;

        prevSlideButton?.classList.add("fadeInUp");
        prevSlideButton?.classList.add("animated");
        prevSlideButton.style.opacity = 1;

        currentSlideImage?.classList.add("zoomInImage");
        currentSlideImage?.classList.add("animated");
        currentSlideImage.style.opacity = 1;
      }
    }
  };

  const settings = {
    dots: true,
    autoplay: false,
    fade: false,
    speed: 800,
    lazyLoad: "progressive",
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange,
    afterChange,
  };

  useEffect(() => {
    const animate = async () => {
      const prevSlideElement =
        await slider.current.innerSlider.list.querySelector(
          `[data-index="${0}"]`
        );
      if (!prevSlideElement) return;

      const prevSlideImage = await prevSlideElement?.querySelector(
        ".banner-col-img"
      );
      const prevSlideHeading = await prevSlideElement?.querySelector("h2");
      const prevSlideSummary = await prevSlideElement?.querySelector(
        ".slidecontent"
      );
      const prevSlideButton = await prevSlideElement?.querySelector(
        ".slide-btn"
      );

      if (
        prevSlideButton &&
        prevSlideElement &&
        prevSlideImage &&
        prevSlideHeading &&
        prevSlideSummary
      ) {
        prevSlideHeading?.classList.add("fadeInUp");
        prevSlideHeading?.classList.add("animated");
        prevSlideHeading.style.opacity = 1;

        prevSlideSummary?.classList.add("fadeInUp");
        prevSlideSummary?.classList.add("animated");
        prevSlideSummary.style.opacity = 1;

        prevSlideButton?.classList.add("fadeInUp");
        prevSlideButton?.classList.add("animated");
        prevSlideButton.style.opacity = 1;

        prevSlideImage?.classList.add("zoomInImage");
        prevSlideImage?.classList.add("animated");
        prevSlideImage.style.opacity = 1;
      }
    };
    let tout;
    if (isLoading === false) {
      tout = setTimeout(() => {
        try {
          animate();
        } catch (err) {
          console.error(err);
        }
      }, 1000);
    }
    return () => {
      clearTimeout(tout);
    };
  }, [isLoading]);

  return (
    <section className="banner-slider">
      <div className="slider stick-dots">
        <Slider {...settings} ref={slider}>
          {presidentImage && (
            <Slide
              heading={presidentMessage}
              summary={presidentContent}
              imgUrl={presidentImage}
              showModal={setShowModal}
            />
          )}
          {carousel &&
            carousel.length &&
            carousel.map((slide, index) => (
              <div key={index} className="slide">
                <div className="banner-full-img">
                  <img src={slide} alt="" loading="eager" />
                </div>
              </div>
            ))}
        </Slider>
      </div>
      <Modal
        showModal={showModal}
        closeModal={setShowModal}
        heading={presidentMessage}
        summary={presidentContent}
        imgUrl={presidentImage}
      />
    </section>
  );
};

export default MainCarousel;
