export const URL_PATH = {
  home: "/",
  history: "/history",
  about: "/about",
  social: "/social-responsibility",
  gallery: "/gallery",
  contact: "/contactus",
  news: "/news",
  newsbyid: "/news",
  events: "/events",
  eventbyid: "/event",
  donation: "/donation",
};

export const API_PATHS = {
  home: "/api/Frontend/GetHomePageDetails",
  history: "/api/Frontend/GetHistoryPageDetails",
  about: "/api/Frontend/GetAboutPageDetails",
  social: "/api/Frontend/GetSocialResponsibilityDetails",
  gallery: "/api/Frontend/GetGalleryDetails",
  contact: "/api/Frontend/GetContactsDetails",
  news: "/api/Frontend/GetNewsDetails",
  newsbyid: "/api/Frontend/GetNewsDetailsById",
  events: "/api/Frontend/GetEventDetails",
  eventbyid: "/api/Frontend/GetEventDetailsById",
  donation: "/api/Frontend/GetDonationDetails",
};
