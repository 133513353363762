import React from "react";
import ContactUs from "../Pages/ContactUs";
import Gallery from "../Pages/Gallery";
import SocialResponsibility from "../Pages/SocialResponsibility";
import About from "../Pages/About";
import History from "../Pages/History";
import Home from "../Pages/Home";
import { Route, Routes } from "react-router-dom";
import Events from "../Pages/Events";
import News from "../Pages/News";
import Donation from "../Pages/Donation";
import Jobs from "../Pages/Jobs";
import JoinUs from "../Pages/JoinUs";
import NewsDetails from "../Pages/NewsDetails";
import EventDetails from "../Pages/EventDetails";

const OpenRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/history" element={<History />} />
      <Route path="/about" element={<About />} />
      <Route path="/social-responsibility" element={<SocialResponsibility />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/news/:id" element={<NewsDetails />} />
      <Route path="/news" element={<News />} />
      <Route path="/event/:id" element={<EventDetails />} />
      <Route path="/events" element={<Events />} />
      <Route path="/donation" element={<Donation />} />
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/membership" element={<JoinUs />} />
      {/* <Route path='/*' element={<NotFound />} /> */}
    </Routes>
  );
};

export default OpenRoutes;
