import React, { useEffect, useState } from "react";
import Header from "../Components/Header.jsx";
import Footer from "../Components/Footer.jsx";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../Styles/all.min.css";
import "../Styles/bootstrap.min.css";
import "../Styles/slick.css";
import "../Styles/slick-theme.css";
import "../Styles/animate.min.css";
import "../Styles/aos.css";
import "../Styles/owl.carousel.min.css";
import "../Styles/style.css";
import "../Styles/responsive.css";
import { getApiResponse } from "../Utils/AxiosRequest.js";
import { ApiDataContext } from "../Contexts/ApiData.js";
import { useLocation } from "react-router-dom";
import { getApiUrl } from "../Utils/format.js";
import { Spinner } from "react-bootstrap";
import { API_PATHS } from "../Utils/Constants/constants.js";

const DefaultLayout = ({ children }) => {
  const [apiData, setApiData] = useState({});
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();

  const fetchData = async () => {
    setError("");
    let apiPath = getApiUrl(pathname);
    if (apiPath == API_PATHS.eventbyid || apiPath == API_PATHS.newsbyid) {
      apiPath = API_PATHS.home;
    }
    if (!apiPath) return;
    try {
      setIsLoading(true);
      const response = await getApiResponse(apiPath);
      if (response.status == 200) {
        setApiData(response.data);
      } else {
        setError("Something went wrong");
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, [pathname]);
  return (
    <ApiDataContext.Provider value={{ ...apiData, isLoading }}>
      <Header />
      {error ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ minHeight: "500px" }}
        >
          <h1>{error}</h1>
          <button
            className="btn"
            onClick={() => {
              fetchData();
            }}
          >
            Refresh
          </button>
        </div>
      ) : isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "500px" }}
        >
          <Spinner />
        </div>
      ) : (
        <div style={{ minHeight: "500px" }}>{children}</div>
      )}
      <Footer />
    </ApiDataContext.Provider>
  );
};

export default DefaultLayout;
