import React, { useContext } from "react";
import { ApiDataContext } from "../Contexts/ApiData";
import Banner from "../Components/Banner";
import ContactUsForm from "../Components/Forms/ContactUsForm";
import OfficeAddressCard from "../Components/Cards/OfficeAddressCard";

import w10 from "../Assets/img/icon-w-10.png";
import w9 from "../Assets/img/icon-w-9.png";
import w8 from "../Assets/img/icon-w-8.png";

const ContactUs = () => {
  const apiData = useContext(ApiDataContext);

  const pageBanner = apiData?.PageBanner;
  const offices = apiData?.Offices;
  const fullAddress = apiData?.CMSPage?.FullAddress;
  const contactInfo = apiData?.CMSPage?.ContactInfo;
  const emailAddress = apiData?.CMSPage?.EmailAddress;

  return (
    <>
      {pageBanner && pageBanner.length ? (
        <Banner
          heading={pageBanner[0].PageBannerTitle}
          imgUrl={pageBanner[0].DisplayURL}
          alt={"Contact Us"}
        />
      ) : (
        <></>
      )}
      <section className="contact-us-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="main-title">Support & General Inquiries</h2>
              <p>
                We endeavour to provide the best possible service and support.
                If you've got a question or a concern, send us an email or give
                us a call and we'll respond as quickly as possible.
              </p>
              <div className="common-form">
                <ContactUsForm />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact-info-wrapper text-white">
                <div className="contact-info-title">
                  <h5 className="sub-title-2 text-white">Get Information</h5>
                  <p>
                    Our Contact information Details and Follow us on social
                    media
                  </p>
                </div>
                <div className="contact-info-content">
                  <div className="contact-info-item">
                    <div className="contact-info-inner">
                      <div className="contact-info-thumb">
                        <img src={w10} alt="address" />
                      </div>
                      <div className="contact-info-details">
                        <span className="text-white">Office Address</span>
                        <p>{fullAddress}</p>
                      </div>
                    </div>
                  </div>
                  <div className="contact-info-item">
                    <div className="contact-info-inner">
                      <div className="contact-info-thumb">
                        <img src={w8} alt="address" />
                      </div>
                      <div className="contact-info-details">
                        <span className="text-white">Phone Number</span>
                        <p>{contactInfo}</p>
                      </div>
                    </div>
                  </div>
                  <div className="contact-info-item">
                    <div className="contact-info-inner">
                      <div className="contact-info-thumb">
                        <img src={w9} alt="address" />
                      </div>
                      <div className="contact-info-details">
                        <span className="text-white">Send Mail</span>
                        <p>{emailAddress}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {offices && offices.length ? (
        <section
          className={`our-office aos-init aos-animate`}
          data-aos="fade-up"
        >
          <div className="container">
            <h2 className="main-title">
              Our <span>Offices</span>
            </h2>
            <div className="row">
              <div className="col-md-12">
                {offices.map((office) => (
                  <OfficeAddressCard key={office.OfficeId} office={office} />
                ))}
              </div>
              {/* {apiData.address.map((add, index) => (
              <div
                className={`col-lg-4 col-sm-6 aos-init aos-animate`}
                data-aos="fade-left"
              >
                <MapCard
                  key={index}
                  address={add.address}
                  email={add.email}
                  heading={add.heading}
                  phoneNo={add.phoneNo}
                  url={add.url}
                />
              </div>
            ))} */}
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default ContactUs;
