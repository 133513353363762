import React, { useContext, useState } from "react";
import NewsCard from "../Components/Cards/NewsCard";

import ButtonSlider from "../Components/Carousel/ButtonSlider";
import { ApiDataContext } from "../Contexts/ApiData";
import Banner from "../Components/Banner";
import { useNavigateToNews } from "../Hooks/Navigation";
import { getDateTime } from "../Utils/format";

const News = () => {
  const navigateToNews = useNavigateToNews();

  const [displayRecords, setDisplayRecords] = useState(6);

  const apiData = useContext(ApiDataContext);

  const pageBanner = apiData?.PageBanner;
  const newsList = apiData?.News;
  const latestNewsList = apiData?.LatestNews;

  return (
    <>
      {pageBanner && pageBanner.length ? (
        <Banner
          heading={pageBanner[0].PageBannerTitle}
          imgUrl={pageBanner[0].DisplayURL}
          alt={"News"}
        />
      ) : (
        <></>
      )}
      {newsList && newsList.length ? (
        <section className="upcoming-event">
          <div className="container">
            <div className="monin-service-title">
              <h3 className="main-title text-center mb-3">
                AIMC's All Latest News
              </h3>
            </div>
            <div className="row">
              {newsList.slice(0, displayRecords).map((news) => (
                <div className="col-lg-4 col-sm-6 mt-4" id="event-card">
                  <NewsCard
                    imgUrl={news?.DisplayURL}
                    date={news?.ScheduleDate}
                    heading={news?.NewsTitle}
                    summary={news?.NewsContent}
                    readmore={() => navigateToNews(news?.NewsId)}
                  />
                </div>
              ))}
            </div>

            {displayRecords < newsList.length && (
              <div className="mt-5">
                <div className="text-center">
                  <button
                    href="#"
                    className="btn green-btn"
                    id="loadMore"
                    onClick={() => setDisplayRecords((prvs) => prvs + 3)}
                  >
                    Load More
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
      ) : (
        <></>
      )}
      {latestNewsList && latestNewsList.length ? (
        <section className="latest-news">
          <div className="container">
            <h2 className="main-title mb-3">Latest Events</h2>
            <div className="latest-news-row">
              <ButtonSlider>
                <>
                  {latestNewsList.slice(0, displayRecords).map((news) => (
                    <div className="col-lg-12 mt-4">
                      <div className="event-2">
                        <div className="event-img">
                          <img src={news?.DisplayURL} alt="" />
                        </div>
                        <div className="event-body">
                          <h3>
                            <a
                              href={news?.NewsTitle}
                              className="sub-title-2 d-block text-truncate pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                navigateToNews(news?.NewsId);
                              }}
                            >
                              {news?.NewsTitle}
                            </a>
                          </h3>
                          {news?.ScheduleDate && (
                            <div className="recent-event-date">
                              <i className="far fa-calendar-alt green-text"></i>{" "}
                              <span className="pl-2">
                                {getDateTime(news?.ScheduleDate)}
                              </span>{" "}
                            </div>
                          )}
                          {news?.NewsLocation && (
                            <div className="recent-event-date">
                              <i className="fas fa-map-marker-alt green-text"></i>{" "}
                              <span className="pl-2">{news?.NewsLocation}</span>{" "}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              </ButtonSlider>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default News;
