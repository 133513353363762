import React, { useContext } from "react";

import { ApiDataContext } from "../Contexts/ApiData";
import ButtonSlider from "../Components/Carousel/ButtonSlider";
import Banner from "../Components/Banner";

const SocialResponsibility = () => {
  const apiData = useContext(ApiDataContext);

  const pageBanner = apiData?.PageBanner;
  const keyInitiative = apiData?.KeyInitiative;
  const socialImage = apiData?.CMSPage?.Display_SocialImagePath;
  const keyInitiativeContent = apiData?.CMSPage?.KeyInitiativeContent;
  const socialContent = apiData?.CMSPage?.SocialContent;

  return (
    <>
      {pageBanner && pageBanner.length ? (
        <Banner
          heading={pageBanner[0].PageBannerTitle}
          imgUrl={pageBanner[0].DisplayURL}
          alt={"Social Responsibility"}
        />
      ) : (
        <></>
      )}
      {socialContent ? (
        <section className="commitment-positive">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="commitment-img">
                  <img src={socialImage} alt="" />
                </div>
                <div>
                  <div
                    className="html_render"
                    dangerouslySetInnerHTML={{ __html: socialContent }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}

      {keyInitiative && keyInitiative.length ? (
        <section className="key-initiatives">
          <div className="container">
            <h2 className="main-title mb-4">Key Initiatives</h2>
            <div
              dangerouslySetInnerHTML={{ __html: keyInitiativeContent }}
            ></div>
            <ButtonSlider className="new-key-initiatives">
              <>
                {keyInitiative.map((initiative) => (
                  <div className="new-key-initiatives-col">
                    <div className="key-initiatives-col">
                      <div className="key-initiatives-img">
                        <img src={initiative?.DisplayURL} alt="" />
                      </div>
                      <h3>{initiative?.KeyTitle}</h3>
                    </div>
                  </div>
                ))}
              </>
            </ButtonSlider>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default SocialResponsibility;
