import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const getApiResponse = async (path) => {
  try {
    const response = await axiosInstance.get(path);
    if (response.status === 200) {
    }
    return {
      status: response.status,
      data: response.data.Response,
    };
  } catch (error) {
    console.error(error.status, error.message);
    return {
      status: error.status,
      data: error.message,
    };
  }
};
