import React, { useState } from "react";
import Modal from "../Modal";

const UserCard = ({
  imgUrl,
  name,
  summary,
  description,
  duration,
  readMore,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
      <div className="card text-center border-none team-item-1">
        <div className="lab-inner">
          <div className="lab-thumb">
            <img src={imgUrl} className="card-img-top" alt="product" />
          </div>
          <div className="lab-content">
            <a href="#">
              <h6 className="card-title mb-0">{name}</h6>
            </a>
            <div className=" mb-3">
              <p className="card-text m-0">{summary}</p>
              <p className="card-text">{duration}</p>
            </div>
            {readMore && (
              <div className="social-share">
                <a
                  data-toggle="modal"
                  data-target="#myModal-1"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                  }}
                >
                  Read More
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        showModal={showModal}
        closeModal={setShowModal}
        heading={name}
        summary={description}
        imgUrl={imgUrl}
      />
    </div>
  );
};

export default UserCard;
